<template>
  <div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Delivery Date"
          label-align-sm="right"
          label-size="sm"
          label-for="delivery_date"
          class="required"
        >
          <b-form-input
            v-model="init.delivery_date.value"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="delivery_date"
            :disabled="init.delivery_date.disabled"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Logistics"
          label-align-sm="right"
          label-size="sm"
          label-for="logistics"
        >
          <SelectLoading
            v-if="options.logistics == 0"
            :size="0.7"
          ></SelectLoading>
          <sv-select
            v-model="init.logistics.value"
            :placeholder="
              options.logistics == 0
                ? $t('page.loading')
                : $t('page.please_choose')
            "
            :clearable="!init.logistics.disabled"
            :options="options.logistics"
            :reduce="options => options.value"
            :disabled="options.logistics == 0 || init.logistics.disabled"
          ></sv-select>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Tracking No."
          label-align-sm="right"
          label-size="sm"
          label-for="tracking_no"
        >
          <b-form-input
            v-model="init.tracking_no.value"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="tracking_no"
            :disabled="init.tracking_no.disabled"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SelectLoading from '@/components/Loading/Index';
export default {
  name: 'BrandArticleNumber',

  data() {
    return {};
  },
  props: ['init', 'options'],
  components: { SelectLoading },
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
