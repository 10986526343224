<template>
  <div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Purchase ID"
          label-align-sm="right"
          label-size="sm"
          label-for="purchase_id"
        >
          <b-form-input
            v-model="init.purchase_id.value"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="purchase_id"
            :disabled="init.purchase_id.disabled"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Status"
          label-align-sm="right"
          label-size="sm"
          label-for="status"
        >
          {{ init.status.value }}
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Purchase Date"
          label-align-sm="right"
          label-size="sm"
          label-for="purchase_date"
        >
          <b-input-group>
            <template v-slot:append>
              <div
                class="datepicker-x"
                v-if="
                  !init.purchase_date.disabled &&
                    init.purchase_date.value != null &&
                    init.purchase_date.value != ''
                "
                @click="initialTime(init.purchase_date)"
              >
                <b-icon-x font-scale="1.45"></b-icon-x>
              </div>
              <b-input-group-text>
                <b-icon-calendar2-date></b-icon-calendar2-date>
              </b-input-group-text>
            </template>
            <b-form-datepicker
              v-model="init.purchase_date.value"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              :hide-header="true"
              :placeholder="'Form'"
              :disabled="init.purchase_date.disabled"
              locale="de"
            ></b-form-datepicker>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1 select-box">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Priority"
          label-align-sm="right"
          label-size="sm"
          label-for="priority"
        >
          <SelectLoading
            v-if="options.priority == 0"
            :size="0.7"
          ></SelectLoading>

          <sv-select
            v-model="init.priority.value"
            :placeholder="
              options.priority == 0
                ? $t('page.loading')
                : $t('page.please_choose')
            "
            :clearable="!init.priority.disabled"
            :options="options.priority"
            :reduce="options => options.value"
            :disabled="options.priority == 0 || init.priority.disabled"
          ></sv-select>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order ID"
          label-align-sm="right"
          label-size="sm"
          label-for="order_id"
        >
          <b-form-input
            v-model="init.order_id.value"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="order_id"
            :disabled="init.order_id.disabled"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1 select-box">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order Preferred"
          label-align-sm="right"
          label-size="sm"
          label-for="priority"
        >
          <SelectLoading
            v-if="options.order_preferred == 0"
            :size="0.7"
          ></SelectLoading>
          <sv-select
            v-model="init.order_preferred.value"
            :placeholder="
              options.order_preferred == 0
                ? $t('page.loading')
                : $t('page.please_choose')
            "
            :clearable="!init.order_preferred.disabled"
            :options="options.order_preferred"
            :reduce="options => options.value"
            :disabled="
              options.order_preferred == 0 || init.order_preferred.disabled
            "
          ></sv-select>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order Date"
          label-align-sm="right"
          label-size="sm"
          label-for="order_date"
        >
          <b-form-input
            v-model="init.order_date.value"
            :type="'text'"
            :placeholder="this.$t('page.please_choose_input')"
            id="order_date"
            :disabled="init.order_date.disabled"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Delivery to"
          label-align-sm="right"
          label-size="sm"
          label-for="delivery_to"
        >
          <SelectLoading
            v-if="options.delivery_to == 0"
            :size="0.7"
          ></SelectLoading>
          <sv-select
            v-model="init.delivery_to.value"
            :placeholder="
              options.delivery_to == 0
                ? $t('page.loading')
                : $t('page.please_choose')
            "
            :clearable="!init.delivery_to.disabled"
            :options="options.delivery_to"
            :reduce="options => options.value"
            :disabled="options.delivery_to == 0 || init.delivery_to.disabled"
          ></sv-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Purchase Source"
          label-align-sm="right"
          label-size="sm"
          label-for="purchase_source"
        >
          <b-form-radio-group
            id="purchase_source"
            v-model="init.purchase_source.value"
            :options="options.purchase_source"
            name="radio-options"
          ></b-form-radio-group
        ></b-form-group>
      </b-col>
    </b-row>
    <!-- {{ init }} -->
    <MerchantPartner
      :init="init"
      :options="options"
      v-bind="$attrs"
      v-on="$listeners"
    ></MerchantPartner>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import SelectLoading from '@/components/Loading/Index';
import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'Information',

  data() {
    return {};
  },
  props: {
    init: Object,
    options: Object
  },
  inheritAttrs: false,
  components: {
    SelectLoading,
    MerchantPartner
    // Others
  },
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
